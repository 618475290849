// Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { EntityService } from '../core/entity/entity.service';
import { AlertService } from '../shared/alert.service';
import { sortBy } from '../shared/util';
import { QuestionnaireSection } from './questionnaire-section.model';

@Injectable({
  providedIn: 'root',
})
export class QuestionnaireSectionService extends EntityService<QuestionnaireSection> {
  constructor(
    protected alertService: AlertService,
    protected httpClient: HttpClient,
  ) {
    super(alertService, httpClient);
  }

  getAllByQuestionnaire$(questionnaireId: number): Observable<QuestionnaireSection[]> {
    const req$ = super.getAll$({ questionnaireId: questionnaireId.toString() });

    return this.handleGetOrCreateResponse(req$, {
      log: `Get ${this.pluralIdentifier()} by questionnaire(${questionnaireId}) failed:`,
      nice: `Failed to get ${this.pluralIdentifier()}`,
    }).pipe(map((sections: QuestionnaireSection[]) => sortBy(sections, 'index')));
  }

  pluralIdentifier(): string {
    return 'questionnaire-sections';
  }

  singularIdentifier(): string {
    return 'questionnaire-section';
  }
}
